import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="delivery-form"
export default class extends Controller {
  static targets = ["weight", "length", "vehicleType", "vehicleSelect"]

  connect() {
    this.vehicleProperties = JSON.parse(this.vehicleSelectTarget.dataset.vehicleProperties)
    this.updateDimensionRequirements()
  }

  saveAsDraft(event) {
    // Set the hidden field value to true
    document.getElementById("save_as_draft_field").value = true;
  }

  updateVehicleType() {
    const weight = parseFloat(this.weightTarget?.value) || 0
    const length = parseFloat(this.lengthTarget?.value) || 0

    // Find the appropriate vehicle type where both weight and length are within limits
    let selectedVehicle = Object.entries(this.vehicleProperties).find(([vehicleType, vehicle]) =>
      weight <= vehicle.max_weight && length <= vehicle.max_length
    )[0]

    if(!selectedVehicle) return;

    // Update the radio button selection for the vehicle type
    const selectedRadio = this.vehicleTypeTargets.find(input => input.value === selectedVehicle)
    if (selectedRadio) {
      selectedRadio.checked = true
      selectedRadio.dispatchEvent(new Event("click")) // Trigger events associated with manual click
    }
  }

  updateDimensionRequirements() {
    const selectedVehicleType = this.vehicleTypeTargets.find(input => input.checked)?.value
    if (selectedVehicleType === 's-van' || selectedVehicleType === 'm-van') {
      this.weightTarget.required = true
      this.lengthTarget.required = true
    } else {
      this.weightTarget.required = false
      this.lengthTarget.required = false
    }
  }
}
